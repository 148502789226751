import React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import "./index.scss"
import "./treneri.scss"

import ImgTreneriPixa from "../images/treneri/treneri-jiri-pixa.jpg"
import ImgTreneriSevcik from "../images/treneri/treneri-jiri-sevcik.jpg"
import ImgTreneriRezler from "../images/treneri/treneri-zdenek-rezler.jpg"
import ImgTreneriMihalikova from "../images/treneri/treneri-monika-mihalikova.jpg"
import ImgTreneriZabystrzan from "../images/treneri/treneri-petr-zabystrzan.jpg"
import ImgTreneriMaizlish from "../images/treneri/treneri-irina-maizlish.jpg"
import ImgTreneriSuchy from "../images/treneri/treneri-jaroslav-suchy.jpg"
import ImgTreneriHybsova from "../images/treneri/treneri-libuse-hybsova.jpg"
import ImgTreneriTancibudek from "../images/treneri/treneri-milos-tancibudek.jpg"
import ImgTreneriPolacek from "../images/treneri/treneri-marek-polacek.jpg"

const Treneri = () => (

	<Layout>
		<Seo title="Trenéři" description="Trenéři" />
		<div className="stk-container stk-container--top-margin">
			<h1 className="stk-h1">Trenérský tým STK&nbsp;Praha</h1>
			<p>
				Kvalitu výuky zajišťuje profesionální trenérský tým.<br />
				V našem klubu působí profesionální trenéři, odborníci na&nbsp;latinsko-americké i&nbsp;standardní tance.
			</p>

			<Row className="stk-treneri">
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/jiri-pixa">
						<img className="stk-treneri__image" alt="Jiří Pixa" src={ImgTreneriPixa} />
						<h2 className="stk-treneri__name">Jiří Pixa</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/jiri-sevcik">
						<img className="stk-treneri__image" alt="Jiří Ševčík" src={ImgTreneriSevcik} />
						<h2 className="stk-treneri__name">Jiří Ševčík</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/zdenek-rezler">
						<img className="stk-treneri__image" alt="Zdeněk Rezler" src={ImgTreneriRezler} />
						<h2 className="stk-treneri__name">Zdeněk Rezler</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/monika-mihalikova">
						<img className="stk-treneri__image" alt="Monika Mihalíková" src={ImgTreneriMihalikova} />
						<h2 className="stk-treneri__name">Monika Mihalíková</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/petr-zabystrzan">
						<img className="stk-treneri__image" alt="Petr Zabystrzan" src={ImgTreneriZabystrzan} />
						<h2 className="stk-treneri__name">Petr Zabystrzan</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/irina-maizlish">
						<img className="stk-treneri__image" alt="Irina Maizlish" src={ImgTreneriMaizlish} />
						<h2 className="stk-treneri__name">Irina Maizlish</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/jaroslav-suchy">
						<img className="stk-treneri__image" alt="Jaroslav Suchý" src={ImgTreneriSuchy} />
						<h2 className="stk-treneri__name">Jaroslav Suchý</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/libuse-hybsova">
						<img className="stk-treneri__image" alt="Libuše Hybšová" src={ImgTreneriHybsova} />
						<h2 className="stk-treneri__name">Libuše Hybšová</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/milos-tancibudek">
						<img className="stk-treneri__image" alt="Miloš Tancibudek" src={ImgTreneriTancibudek} />
						<h2 className="stk-treneri__name">Miloš Tancibudek</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
				<Col xs="6" sm="4" md="3" lg="2" xl="2">
					<Link className="stk-treneri__item" to="/treneri/marek-polacek">
						<img className="stk-treneri__image" alt="Marek Poláček" src={ImgTreneriPolacek} />
						<h2 className="stk-treneri__name">Marek Poláček</h2>
						<div className="stk-button stk-button--small stk-treneri__button">detail</div>
						<div className="stk-treneri__bg"></div>
					</Link>
				</Col>
			</Row>

		</div>
	</Layout>

)

export default Treneri